<template>
  <div class="attendance">
    <div class="attendance-all">
      <div class="title">
        <div @click="goBack" v-if="roleType == 1"><i class="el-icon-arrow-left"></i></div>
        <div class="title-item">上座率</div>
      </div>
      <div class="sum">
        <p class="sum-left">当前在线人数</p>
        <p class="personnel">{{ obj.onlineMember }}</p>
        <div class="item">
          <div class="item-left">{{shopName}}</div>
          <div class="item-right">总会员数{{ obj.totalMemberNum }}</div>
        </div>
      </div>
    </div>
    <div class="item-all">
      <ul class="item-total">
        <li>
          <p>总机器数</p>
          <p>{{ obj.deviceNum }}</p>
        </li>
        <li>
          <p>空器数</p>
          <p>{{ obj.freeDeviceNum }}</p>
        </li>
        <li>
          <p>会员</p>
          <p>{{ obj.memberNum }}</p>
        </li>
        <li>
          <p>上座率</p>
          <p>{{ obj.rate +'%' || '0%'}}</p>
        </li>
      </ul>
      <div class="item-bar">
        <div class="bar-title">
          <div class="title-left">近7天上网人数</div>
          <div class="title-right">
            时段：{{ startTimeShow }}-{{ endTimeShow }}
          </div>
        </div>
        <div class="bar-show">
          <div id="bar"></div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import { Toast, TreeSelect } from "vant";
import Footer from "@/components/foot.vue";
export default {
  name: "attendance",
  components: {
    Footer
  },
  data() {
    return {
      shopName: '',
      roleType: 0,
      shopId: "",
      startTimeShow: "",
      endTimeShow: "",
      obj: {
        list: [],
        rate: 0,
        deviceNum: 0,
        freeDeviceNum: 0,
        memberNum: 0,
        onlineMember: 0,
        totalMemberNum: 0,
      }
    };
  },
  mounted() {
    this.shopName = localStorage.shopName
    this.roleType = localStorage.roleType;
    this.shopId = this.$route.query.shopId || localStorage.shopId;
    this.handleFirstData();
  },
  methods: {
    handleFirstData() {
      let dataTime = this.parseTime(new Date(), "{y}-{m}-{d}");
      let _this = this;
      var myDate = new Date(); //获取今天日期
      myDate.setDate(myDate.getDate() - 7);
      let endTime = this.parseTime(new Date()-24*60*60*1000, "{y}-{m}-{d}");
      this.endTimeShow= this.parseTime(new Date()-24*60*60*1000, "{m}.{d}");
      let startTime = this.parseTime(new Date(myDate), "{y}-{m}-{d}");
      this.startTimeShow = this.parseTime(new Date(myDate), "{m}.{d}");
      let params = {
        shopId: this.shopId,
        startTime: startTime,
        endTime: endTime
      };
      _this.$API.getOnTheChance(params).then(function(res) {
        if (res.code == 200) {
          _this.obj = res.data;
          _this.handlebarData();
        } else {
          Toast(res.msg);
        }
      });
    },
    // 柱形图
    handlebarData() {
      let that = this;
      let valArr = [];
      let textArr = [];
      let arr = this.obj.list;
      if (arr.length != 0) {
        for (let i in arr) {
          valArr.push(arr[i].number);
          textArr.push(arr[i].time);
        }
      }
      //初始化
      let myChart = that.$echarts.init(document.getElementById("bar"));
      // 回执图表
      myChart.setOption({
        tooltip: {
          show: false
        },
        color: ["#4DBB81", "#6D8EFA"],
        legend: { show: false },
        grid: {
          top: "8%",
          left: "3%",
          right: "2%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          // type: "value",
          data: textArr,
          axisTick: {
            show: false
          },
          axisLine: { 
            onZero: false ,
            show: false
          },
          axisLabel: {
            interval:0 ,
            fontSize: 12
          },
          splitLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["#EDEDED", "#EDEDED"],
              type: 'dashed' // y轴分割线类型
            },
          }
        },
        yAxis: {
          axisTick: {
            show: false
          },
          axisLine: { 
            onZero: false ,
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["#EDEDED", "#EDEDED"],
              type: 'dashed' // y轴分割线类型
            },
          }
        },
        series: [
          {
            name: "充值",
            type: "bar",
            data: valArr,
            barWidth: 16, //设置柱状图大小
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#656565"
                  },
                  formatter: function(params) {
                    if (params.value == 0) {
                      return params.value;
                    }
                  }
                },
                barBorderRadius: 4,
                color: new that.$echarts.graphic.LinearGradient(1, 0, 0, 1, [
                  { offset: 0, color: "#AEC1FD" }, //初始颜色
                  { offset: 1, color: "#3F6EFB" } //渐变到这个色
                ])
              }
            }
          }
        ]
      });
      window.onresize = myChart.resize;
    },
    goBack() {
      this.$router.push({ name: "Store" });
    }
  }
};
</script>
<style lang="scss" scoped>
.attendance {
  position: relative;
  padding-bottom: 300px;
  // height: 100vh;
  .attendance-all {
    height: 420px;
    color: #ffffff;
    padding: 30px;
    background: url("~@/assets/img/total/titleBg.png");
    background-size: 100% 100%;
    .title {
      display: flex;
      align-items: center;
      i {
        font-size: 30px;
      }
      .title-item{
        flex: 5;
        text-align: center;
        font-size: 38px;

      }
    }
    .sum {
      margin-top: 36px;
      .sum-left {
        font-size: 22px;
      }
      .personnel {
        margin-top: 21px;
        font-size: 48px;
        font-weight: bold;
      }
      .item {
        display: flex;
        font-size: 22px;
        margin-top: 42px;
        .item-left {
        }
        .item-right {
          margin-left: 42px;
        }
      }
    }
  }
  .item-all {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 62%;
    transform: translate(-50%, -50%);
    z-index: 33;
    .item-total {
      display: flex;
      align-items: center;
      color: #ffffff;
      background: #6e9afe;
      padding-top: 60px;
      padding-bottom: 150px;
      border-radius: 80px 80px 0px 0;
      li {
        text-align: center;
        margin: auto;
        > p:nth-child(1) {
          font-size: 22px;
        }
        > p:nth-child(2) {
          font-size: 30px;
          margin-top: 10px;
          font-weight: bold;
        }
      }
    }
    .item-bar {
      position: absolute;
      width: 100%;
      background: #ffffff;
      padding: 40px 0;
      left: 50%;
      top: 90%;
      transform: translate(-50%, -10%);
      border-radius: 80px 80px 0px 0;
      z-index: 33;
      .bar-title {
        display: flex;
        padding: 0 40px;
        .title-left {
          font-size: 24px;
          color: #000;
        }
        .title-right {
          color: #afb6cd;
          margin-left: auto;
          font-size: 22px;
          margin-top: 10px;
        }
      }
      .bar-show {
        padding: 50px 40px;
        margin-bottom: 40px;
      }
      #bar {
        width: 100%;
        height: 420px;
      }
    }
  }
}
</style>
