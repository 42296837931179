<template>
  <div class="footTab">
    <ul class="footTab-ul">
      <li :class="type == item.id?'navColor':''" v-for="(item,idx) in navList" :key="idx" @click="handleNavData(item,idx)">
        <img v-if="type == item.id" :src="item.imgUrlShow"/>
        <img v-else :src="item.imgUrl"/>
        <div>{{item.name}}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'footTab',
  props: {
    msg: String
  },
  data(){
    return{
      navList:[
        {
          id: 1,
          name: '资金收入',
          path: 'Capital',
          imgUrl: require('../assets/img/nav1.png'),
          imgUrlShow: require('../assets/img/navShow1.png'),
        },
        {
          id: 2,
          name: '营业收入',
          path: 'Business',
          imgUrl: require('../assets/img/nav2.png'),
          imgUrlShow: require('../assets/img/navShow2.png'),
        },
        {
          id: 3,
          name: '上座率',
          path: 'Attendance',
          imgUrl: require('../assets/img/nav3.png'),
          imgUrlShow: require('../assets/img/navShow3.png'),
        },
      ],
      type: 1,
    }
  },
  mounted() {
    switch(this.$route.path){
      case '/Capital':
        this.type = 1
        break
      case '/Business':
        this.type = 2
        break
      case '/Attendance':
        this.type = 3
        break
    }

  },
  methods: {
    handleNavData(data,idx) {
      this.type = data.id
      switch(this.type){
        case 1:
          this.$router.push({name:data.path});
          break
        case 2:
          this.$router.push({name: data.path});
          break
        case 3:
          this.$router.push({name: data.path});
          break
      }
    }
  },
}
</script>
<style lang="scss" scoped>
  .footTab{
    width: 100%;
    overflow: hidden;
    >ul {
      width: 100%;
      height: 100px;
      // line-height: 90px;
      position: fixed;
      bottom: 0;
      left: 0;
      background: #ffffff;
      display: flex;
      justify-content: center;

      box-shadow: 0px 6px 40px 0px rgba(0, 0, 0, 0.1);
      z-index: 9999;
      li{
        padding: 10px 0;
        flex: 5;
        text-align: center;
        color: #999999;
        font-size: 18px;
        img{
          width: 50px;
          height: 46px;
        }
      }
      .navColor{
        color: #677491 !important;
      }

    };
  }
</style>
